import React, { memo, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { navList } from '../data/Data';

const Header = () => {
  const [navbarCollapse, setNavbarCollapse] = useState(false); // Initialize collapse state
  const [activeDropdown, setActiveDropdown] = useState(null); // Initialize dropdown state
  const { isLoggedIn, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleMouseEnter = (itemId) => {
    setActiveDropdown(itemId); // Set active dropdown on hover
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null); // Reset dropdown on leave
  };

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to home after logout
  };

  return (
    <div className="container-fluid bg-dark px-0">
      <div className="row gx-0">
        <div className="col-lg-3 bg-dark d-none d-lg-block">
          <Link
            to="/"
            className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center"
          >
            <img src="/assets/img/logo.png" width={100} alt="Logo" />
          </Link>
        </div>
        <div className="col-lg-9">
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
            <Link to="/" className="navbar-brand d-block d-lg-none">
              <img src="/assets/img/logo.png" width={100} alt="Logo" />
            </Link>
            <button
              type="button"
              className="navbar-toggler"
              onClick={() => setNavbarCollapse(!navbarCollapse)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`navbar-collapse justify-content-around ${navbarCollapse ? 'show' : 'collapse'}`}
            >
              <div className="navbar-nav mr-auto py-0">
                {navList.map((item, index) => (
                  <div key={index}>
                    {item.subItems ? (
                      <div
                        className="nav-item dropdown"
                        onMouseEnter={() => handleMouseEnter(item.id)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Link className="nav-link dropdown-toggle">{item.text}</Link>
                        <div
                          className={`dropdown-menu rounded-0 m-0 ${activeDropdown === item.id ? 'show' : ''}`}
                        >
                          {item.subItems.map((sub) => (
                            <Link to={sub.path} className="dropdown-item" key={sub.id}>
                              {sub.text}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Link to={item.path} className="nav-item nav-link">
                        {item.text}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
              <div className="navbar-nav ml-auto">
                {isLoggedIn ? (
                  <>
                    <Link to="/mybookings" className="nav-item nav-link">
                      My Booking
                    </Link>
                    <button
                      className="btn btn-link nav-item nav-link"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <Link to="/login" className="nav-item nav-link">
                    Login
                  </Link>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
