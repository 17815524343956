import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Login from "./components/home/Login";
import Register from "./components/home/Register";
import RoomDetail from "./components/home/RoomDetail";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the protected route component
import "./css/animate.css";
import "./css/animate.min.css";
import "./css/bootstrap.min.css";
import "./css/style.css";
import {
  AboutUs,
  Booking,
  Contact,
  Home,
  MyBookings,
  PageNotFound,
  Room,
  Services,
  Team,
  Testimonial
} from "./pages/index";

export default function App() {
  return (
    <>
      <div>
        <Router>
          <Header />
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/team" element={<Team />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rooms" element={<Room />} />
            <Route path="/services" element={<Services />} />
            <Route path="/room/details/:id" element={<RoomDetail />} />
            
            {/* Protect MyBookings route */}
            <Route path="/mybookings" element={<ProtectedRoute element={MyBookings} />} />

            <Route path="/booking" element={<ProtectedRoute element={Booking} />} />
            
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </>
  );
}
