// Rooms.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CommonHeading from "../common/CommonHeading";
import "./Rooms.css"; // Import CSS file for custom styles

export default function Rooms() {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Slider settings
  const settings = {
    dots: true,
    infinite: false, // Set to false to prevent looping
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // For devices with width less than 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // For devices with width less than 600px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Function to fetch feedbacks for a room and calculate average rating
  const fetchRoomFeedbacks = async (roomId) => {
    try {
      const response = await fetch(`https://34.203.206.155.nip.io/api/feedback/${roomId}`);
      const data = await response.json();
      return data; // Assuming data is an array of feedbacks
    } catch (error) {
      console.error(`Error fetching feedbacks for room ${roomId}:`, error);
      return [];
    }
  };

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch("https://34.203.206.155.nip.io/api/rooms/all");
        const data = await response.json();
        const availableRooms = data.data.filter((room) => room.available);

        // For each room, fetch feedbacks and calculate average rating
        const roomsWithRatings = await Promise.all(
          availableRooms.map(async (room) => {
            const feedbacks = await fetchRoomFeedbacks(room.id);
            const ratings = feedbacks.map((feedback) => feedback.rating);
            const averageRating = ratings.length ? ratings.reduce((a, b) => a + b, 0) / ratings.length : 0;
            return { ...room, averageRating };
          })
        );

        setRooms(roomsWithRatings);
      } catch (error) {
        setError("Failed to fetch rooms. Please try again later.");
        console.error("Error fetching rooms:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRooms();
  }, []);

  // Function to render stars based on average rating
  const renderStars = (rating) => {
    const totalStars = 5;
    const filledStars = Math.round(rating);
    const emptyStars = totalStars - filledStars;

    return (
      <>
        {[...Array(filledStars)].map((_, index) => (
          <small key={`filled-${index}`} className="fa fa-star text-primary"></small>
        ))}
        {[...Array(emptyStars)].map((_, index) => (
          <small key={`empty-${index}`} className="fa fa-star text-secondary"></small>
        ))}
      </>
    );
  };

  if (loading) {
    return <div className="text-center">Loading rooms...</div>; // Show loading spinner or message
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>; // Display error message
  }

  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <CommonHeading heading="Our Rooms" title="Rooms" subtitle="Explore Our" />
          {rooms.length > 0 ? (
            <Slider {...settings}>
              {rooms.map((room) => (
                <div key={room.id} className="p-3">
                  <div className="room-item shadow rounded overflow-hidden">
                    <div className="position-relative">
                      <img
                        className="img-fluid"
                        src={room.images[0]?.url || "/placeholder.jpg"} // Display the first image or placeholder
                        alt={room.name}
                      />
                      <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                        ${room.price}/night
                      </small>
                    </div>
                    <div className="p-4 mt-2">
                      <div className="d-flex justify-content-between mb-3">
                        <h5 className="mb-0">{room.name}</h5>
                        <div className="ps-2">
                          {room.averageRating > 0 ? (
                            renderStars(room.averageRating)
                          ) : (
                            <small>No ratings yet</small>
                          )}
                        </div>
                      </div>
                      <div className="amenities-scroll mb-3">
                        {room.amenities.map((amenity) => (
                          <small key={amenity.id} className="border-end me-3 pe-3">
                            {amenity.name}
                          </small>
                        ))}
                      </div>
                      <p className="text-body mb-3">{room.description}</p>
                      <div className="text-center">
                        <Link
                          className="btn btn-sm btn-primary rounded py-2 px-4"
                          to={`/room/details/${room.id}`}
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="text-center">
              <h1>All rooms are fully booked at the moment.</h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
