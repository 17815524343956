import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { AuthContext } from "../context/AuthContext";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const MyBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, token } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    const fetchBookings = async () => {
      try {
        const response = await fetch("https://34.203.206.155.nip.io/api/booking/my-bookings", {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setBookings(data);
          setLoading(false);
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
        setLoading(false);
      }
    };

    fetchBookings();
  }, [isLoggedIn, token, navigate]);

  const handleCheckin = async (bookingId) => {
    setLoading(true); // Assume you have a loading state
    try {
      const response = await fetch(
        `https://34.203.206.155.nip.io/api/booking/checkin/${bookingId}`,
        {
          method: "PUT",
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        // Update UI accordingly
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.id === bookingId
              ? { ...booking, status: "CHECKED_IN", checkInDate: data.checkInDate }
              : booking
          )
        );
        // Optionally show a success message in the UI
      } else {
        const errorData = await response.json();
        // Display error message in the UI
        setError(`Check-in failed: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error during check-in:", error);
      setError("Check-in failed due to a network error.");
    } finally {
      setLoading(false);
    }
  };  
  
  const handleCheckout = async (bookingId) => {
    try {
      const response = await fetch(
        `https://34.203.206.155.nip.io/api/booking/checkout/${bookingId}`,
        {
          method: "PUT",
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        alert("Checked out successfully!");
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.id === bookingId
              ? { ...booking, status: "CHECKED_OUT" }
              : booking
          )
        );
      } else {
        const errorData = await response.json();
        alert(`Check-out failed: ${errorData.error}`);
        console.error("Check-out error:", errorData.error);
      }
    } catch (error) {
      console.error("Error during check-out:", error);
      alert("Check-out failed due to a network error.");
    }
  };
  

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <BounceLoader />
      </div>
    );
  }

  if (!bookings.length) {
    return <div>No bookings found</div>;
  }

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <h2 className="mb-4">My Bookings</h2>
        <div className="row g-4">
          {bookings.map((booking) => {
            const userTimeZone = dayjs.tz.guess(); // Get user's timezone
            const currentDate = dayjs().utc(); // Use UTC for consistency
            const startDate = dayjs(booking.startDate).utc();
            const endDate = dayjs(booking.endDate).utc();

            return (
              <div
                key={booking.id}
                className="col-lg-4 wow zoomIn"
                data-wow-delay="0.1s"
              >
                <div className="border rounded p-4 h-100 d-flex flex-column justify-content-between">
                  <div>
                    <h5 className="mb-3">{booking.room.name}</h5>
                    <p>
                      <strong>Start Date:</strong>{" "}
                      {startDate.tz(userTimeZone).format("MMMM D, YYYY")} <br />
                      <strong>End Date:</strong>{" "}
                      {endDate.tz(userTimeZone).format("MMMM D, YYYY")} <br />
                      <strong>Status:</strong> {booking.status}
                    </p>
                  </div>
                  <div className="mt-auto d-flex flex-column">
                    {/* Check-In button */}
                    {booking.status.toLowerCase() === "confirmed" &&
                      currentDate.isSameOrAfter(startDate, "day") && (
                        <button
                          className="btn btn-primary mb-2"
                          onClick={() => handleCheckin(booking.id)}
                        >
                          Check In
                        </button>
                      )}

                    {/* Informative message if check-in is not yet allowed */}
                    {booking.status.toLowerCase() === "confirmed" &&
                      currentDate.isBefore(startDate, "day") && (
                        <p className="text-muted">
                          Check-in available on{" "}
                          {startDate.tz(userTimeZone).format("MMMM D, YYYY")}
                        </p>
                      )}

                    {/* Check-Out button */}
                    {booking.status.toLowerCase() === "checked_in" &&
                      currentDate.isSameOrAfter(endDate, "day") && (
                        <button
                          className="btn btn-success"
                          onClick={() => handleCheckout(booking.id)}
                        >
                          Check Out
                        </button>
                      )}

                    {/* Informative message if check-out is not yet allowed */}
                    {booking.status.toLowerCase() === "checked_in" &&
                      currentDate.isBefore(endDate, "day") && (
                        <p className="text-muted">
                          Check-out available on{" "}
                          {endDate.tz(userTimeZone).format("MMMM D, YYYY")}
                        </p>
                      )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MyBookings;