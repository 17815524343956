import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CommonHeading from "../common/CommonHeading";

export default function Teams() {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch team data from server
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch("https://34.203.206.155.nip.io/api/team");
        if (!response.ok) throw new Error("Failed to fetch team members");
        const data = await response.json();
        setTeam(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  const settings = {
    dots: true,
    infinite: false, // Stop the infinite scrolling to prevent repeating
    speed: 500,
    slidesToShow: Math.min(4, team.length), // Only show slides based on the available team members
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(3, team.length),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2, team.length),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, team.length),
        },
      },
    ],
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <CommonHeading heading="Our Team" subtitle="Explore Our" title="Staffs" />
          {team.length > 0 ? (
            <Slider {...settings}>
              {team.map((item) => (
                <div className="p-2" key={item.id}>
                  <div className="rounded shadow overflow-hidden text-center" style={{ width: "250px", margin: "0 auto" }}>
                    <div className="position-relative">
                      <img 
                        className="img-fluid" 
                        src={item.photoUrl} 
                        alt={item.fullName} 
                        style={{ width: "100%", height: "auto", objectFit: "cover" }} 
                      />
                    </div>
                    <div className="p-3">
                      <h5 className="fw-bold mb-1">{item.fullName}</h5>
                      <small>{item.position}</small>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <h4 className="text-center">No team members available.</h4>
          )}
        </div>
      </div>
    </>
  );
}
