// RoomDetail.js
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { AuthContext } from "../../context/AuthContext";
import CommonHeading from "../common/CommonHeading";

export default function RoomDetail() {
  const { id } = useParams();
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [feedbacks, setFeedbacks] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showBookingForm, setShowBookingForm] = useState(false);
  const { isLoggedIn, token } = useContext(AuthContext);
  const navigate = useNavigate();

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const fetchRoom = async () => {
    try {
      const response = await fetch(`https://34.203.206.155.nip.io/api/rooms/single/${id}`);
      const data = await response.json();
      setRoom(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching room:", error);
      setLoading(false);
    }
  };

  const fetchFeedbacks = async () => {
    try {
      const response = await fetch(`https://34.203.206.155.nip.io/api/feedback/${id}`);
      const data = await response.json();
      setFeedbacks(data);
    } catch (error) {
      console.error("Error fetching feedbacks:", error);
    }
  };

  useEffect(() => {
    fetchRoom();
    fetchFeedbacks();
  }, [id]);

  const handleBooking = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      return navigate("/login");
    }

    const bookingData = {
      roomId: parseInt(id), // Convert id to integer
      startDate,
      endDate,
    };

    try {
      const response = await fetch("https://34.203.206.155.nip.io/api/booking/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(bookingData),
      });

      if (response.ok) {
        alert("Booking confirmed!");
        setShowBookingForm(false);
      } else {
        const data = await response.json();
        alert(`Failed to confirm booking: ${data.error}`);
      }
    } catch (error) {
      console.error("Booking error:", error);
    }
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      return navigate("/login");
    }

    const feedbackData = {
      roomId: parseInt(id), // Convert id to integer
      rating,
      comment,
    };

    try {
      const response = await fetch("https://34.203.206.155.nip.io/api/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(feedbackData),
      });

      if (response.ok) {
        alert("Feedback submitted!");
        setRating(0);
        setComment("");
        fetchFeedbacks(); // Refresh the feedbacks
      } else {
        const data = await response.json();
        alert(`Failed to submit feedback: ${data.error}`);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  // Function to render stars based on rating
  const renderStars = (rating) => {
    const totalStars = 5;
    const filledStars = Math.round(rating);
    const emptyStars = totalStars - filledStars;

    return (
      <>
        {[...Array(filledStars)].map((_, index) => (
          <small key={`filled-${index}`} className="fa fa-star text-primary"></small>
        ))}
        {[...Array(emptyStars)].map((_, index) => (
          <small key={`empty-${index}`} className="fa fa-star text-secondary"></small>
        ))}
      </>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!room) {
    return <div>Room not found</div>;
  }

  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <CommonHeading heading={room.name} title="Room Detail" subtitle="Discover" />
          <div className="row g-4">
            {/* Room Info Section */}
            <div className="col-lg-8">
              <div className="shadow rounded overflow-hidden">
                <Slider {...settings}>
                  {room.images.map((image) => (
                    <div key={image.id}>
                      <img className="img-fluid" src={image.url} alt="Room" />
                    </div>
                  ))}
                </Slider>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">{room.name}</h5>
                    <div className="ps-2">${room.price}/night</div>
                  </div>
                  <p className="text-body mb-3">{room.description}</p>
                </div>
              </div>

              {/* Feedback Section */}
              <div className="mt-5">
                <h3>Feedbacks</h3>
                {feedbacks.length > 0 ? (
                  feedbacks.map((feedback) => (
                    <div key={feedback.id} className="mb-3">
                      <div>
                        <strong>{feedback.user.name}</strong>
                        <div>{renderStars(feedback.rating)}</div>
                        <p>{feedback.comment}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No feedbacks yet.</p>
                )}

                {/* Feedback Form */}
                {isLoggedIn ? (
                  <form onSubmit={handleFeedbackSubmit}>
                    <h4>Leave a Feedback</h4>
                    <div className="mb-3">
                      <label htmlFor="rating" className="form-label">
                        Rating
                      </label>
                      <select
                        id="rating"
                        className="form-select"
                        value={rating}
                        onChange={(e) => setRating(parseInt(e.target.value))}
                        required
                      >
                        <option value="">Select rating</option>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <option key={num} value={num}>
                            {num} Star{num > 1 && "s"}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="comment" className="form-label">
                        Comment
                      </label>
                      <textarea
                        id="comment"
                        className="form-control"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit Feedback
                    </button>
                  </form>
                ) : (
                  <p>
                    Please <Link to="/login">login</Link> to leave feedback.
                  </p>
                )}
              </div>
            </div>

            {/* Booking Section */}
            <div className="col-lg-4">
              <div className="shadow rounded overflow-hidden">
                <div className="p-4">
                  <h5 className="mb-3">Room Amenities</h5>
                  <ul className="list-unstyled">
                    {room.amenities.map((amenity) => (
                      <li key={amenity.id} className="mb-2">
                        <i className="bi bi-check-circle text-primary me-2"></i>
                        {amenity.name}
                      </li>
                    ))}
                  </ul>

                  {/* Book Now Button */}
                  <button
                    className="btn btn-sm btn-primary rounded py-2 px-4 mt-4"
                    onClick={() => setShowBookingForm(true)}
                  >
                    Book Now
                  </button>

                  {/* Booking Form (only visible when showBookingForm is true) */}
                  {showBookingForm && (
                    <form onSubmit={handleBooking} className="mt-4">
                      <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          id="startDate"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="endDate" className="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          id="endDate"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required
                        />
                      </div>
                      <button type="submit" className="btn btn-success">
                        Confirm Booking
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
    </>
  );
}
