import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element: Component }) => {
  const token = localStorage.getItem("token"); // Get token from localStorage

  // If the token exists, render the protected component, otherwise redirect to login
  return token ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
